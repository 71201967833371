import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'react-modal';
import { EyeIcon, CheckIcon, XMarkIcon, StarIcon, PaperClipIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import axiosInstance from '../api/axiosConfig';
import numeral from 'numeral';
import Swal from 'sweetalert2';
import UserName from './UserName';
import useUserProfile from '../hooks/useUserProfile';
import Tooltip from './Tooltip';

const EventApprovalDetails = () => {
  // console.log("dafsadfdssdxs")
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [modalState, setModalState] = useState({ isOpen: false, modalType: '' });
  const { data: userProfile } = useUserProfile();

  const approverOrder = userProfile?.orden_aprobador;
  const approverDocumentacionOrder = userProfile?.orden_aprobador_documentacion;
  //console.log(approverDocumentacionOrder)

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axiosInstance.get(`v1/events/aprovers/${eventId}/`);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const openModal = (type) => {
    setModalState({ isOpen: true, modalType: type });
  };

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: '' });
  };

  const handleAction = async (sometimientoId, action) => {
    let comentario = '';

    if (action === 'RECHAZADO') {
      const { value: comment } = await Swal.fire({
        title: 'Rechazar Sometimiento',
        input: 'text',
        inputLabel: 'Comentario',
        inputPlaceholder: 'Ingrese el motivo del rechazo',
        showCancelButton: true,
        confirmButtonText: 'Rechazar',
        cancelButtonText: 'Cancelar',
        inputValidator: (value) => {
          if (!value) {
            return 'El comentario es requerido';
          }
        }
      });

      if (!comment) return; // Si el usuario cancela, se aborta la acción

      comentario = comment;
    }

    try {
      const response = await axiosInstance.post(`v1/submission/update-status/${sometimientoId}/`, {
        estado: `${action}`,
        comentarios: comentario, // Incluye el comentario en el cuerpo de la petición
      });

      Swal.fire('Éxito', `El sometimiento ha sido ${action}`, 'success').then(
        () => window.location.reload()
      )
      
      setEvent((prevEvent) => {
        const updatedEvent = { ...prevEvent };
        updatedEvent.tipo_sometimiento = updatedEvent.tipo_sometimiento.map((presupuesto) => {
          presupuesto.sometimientos = presupuesto.sometimientos.map((sometimiento) => {
            if (sometimiento.id === sometimientoId) {
              sometimiento.ultimo_estado_historial.estado = action.toUpperCase();
              sometimiento.ultimo_estado_historial.comentario = comentario;
            }
            return sometimiento;
          });
          return presupuesto;
        });
        return updatedEvent;
      });
    } catch (error) {
      Swal.fire('Error', `Error al ${action} el sometimiento`, 'error');
    }
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  return (
    <div className="p-4 sm:p-8">
      {event && (
        <>
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-2xl font-bold">Evento: {event.nombre}</h1>
            <div className="flex items-center space-x-4">
              <span>{event.fecha_evento}</span>
              <span>Gs. {numeral(event.monto_estimado).format('0,0').replace(/,/g, '.')}</span>
              <button onClick={() => openModal('viewDetails')} className="flex items-center text-blue-500">
                <EyeIcon className="w-5 h-5 mr-1" />
                Ver detalles
              </button>
            </div>
          </div>

          <Modal
            isOpen={modalState.isOpen && modalState.modalType === 'viewDetails'}
            onRequestClose={closeModal}
            contentLabel="Detalles del Evento"
            className="modal"
            overlayClassName="overlay"
          >
            <h2 className="text-xl font-bold">Detalles del Evento</h2>
            <div className="mt-4 space-y-2">
              <p><strong>Nombre:</strong> {event.nombre}</p>
              <p><strong>Descripción:</strong> {event.descripcion}</p>
              <p><strong>Fecha del Evento:</strong> {event.fecha_evento}</p>
              <p><strong>Monto Estimado:</strong> Gs. {numeral(event.monto_estimado).format('0,0').replace(/,/g, '.')}</p>
              <p><strong>Tipo de Evento:</strong> {event.tipo_evento_nombre}</p>
            </div>
            <button onClick={closeModal} className="px-4 py-2 mt-4 text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              Cerrar
            </button>
          </Modal>

          <div className="space-y-8">
            {event.tipo_sometimiento.map((presupuesto, index) => {
              // Filtrar sometimientos que no estén en estado BORRADOR
              const validSometimientos = presupuesto.sometimientos.filter(
                (item) => item.ultimo_estado_historial.estado !== 'BORRADOR'
              );

              // Si todos los sometimientos son BORRADOR, no mostrar el tipo de sometimiento
              if (validSometimientos.length === 0) return null;

              return (
                <div key={index} className="p-4 bg-white rounded-lg shadow-md">
                  <div className="flex items-center justify-between mb-4">
                    <h2 className="text-xl font-bold">{presupuesto.tipo_sometimiento_nombre}</h2>
                  </div>
                  <table className="w-full bg-white rounded-lg">
                    <thead className="bg-gray-200">
                      <tr>
                        <th className="px-4 py-2 text-left">Título del pedido</th>
                        <th className="px-4 py-2 text-left">Monto</th>
                        <th className="px-4 py-2 text-left">Proveedor</th>
                        <th className="px-4 py-2 text-left">Solicitado por</th>
                        <th className="px-4 py-2 text-left">Adjunto</th>
                        <th className="px-4 py-2 text-left">Estado</th>
                        <th className="px-4 py-2 text-left">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {validSometimientos.map((item, itemIndex) => (
                        <tr key={itemIndex} className="border-t">
                          <td className="px-4 py-2">{item.nombre}</td>
                          <td className="px-4 py-2">Gs. {numeral(item.monto).format('0,0').replace(/,/g, '.')}</td>
                          <td className="px-4 py-2">{item.proveedor}</td>
                          <td className="flex items-center px-4 py-2">
                            <UserName userId={item.solicitado_por} />
                          </td>
                          <td className="px-4 py-2">
                            {item.presupuesto ? (
                              <a href={`${apiUrl}${item.presupuesto}`} target="_blank" rel="noopener noreferrer">
                                <PaperClipIcon className="w-4 h-4 text-gray-500" />
                              </a>
                            ) : (
                              ''
                            )}
                          </td>
                          <td className="px-4 py-2">
                            {item?.ultimo_estado_historial?.estado}
                            <Tooltip position='top' text={item.historial && item.historial.length > 0 ? item.historial[0].comentarios : 'Sin comentarios'}>
                                <InformationCircleIcon className="w-3 h-3 ml-2" />
                            </Tooltip>
                          </td>
                          <td className="flex px-4 py-2 space-x-2">
                            {item.sugerido && item?.ultimo_estado_historial?.estado === 'PENDIENTE' && item.ultimo_estado_historial.orden === approverOrder ? (
                              <>
                                <button
                                  onClick={() => handleAction(item.id, 'APROBADO')}
                                  className="flex items-center px-2 py-1 text-white bg-green-500 rounded hover:bg-green-700 focus:outline-none"
                                >
                                  <CheckIcon className="w-4 h-4 mr-1" />
                                  Aceptar
                                </button>
                                <button
                                  onClick={() => handleAction(item.id, 'RECHAZADO')}
                                  className="flex items-center px-2 py-1 text-white bg-red-500 rounded hover:bg-red-700 focus:outline-none"
                                >
                                  <XMarkIcon className="w-4 h-4 mr-1" />
                                  Rechazar
                                </button>
                              </>
                            ) : (
                              <StarIcon className="w-5 h-5 text-yellow-500" />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default EventApprovalDetails;
